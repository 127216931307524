import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import Details from "@/pagesExample/apps/tasks/Details";
import { useAssignment } from "@/pages_old/Assign/Assignment";
import { useCreateTask } from "@/pages_old/Tasks/CreateTaskModal";
import { updateMeeting } from "@/pages_old/Meetings/DescriptionForm";

import { MeetingShowPageQuery as MeetingShowPageQueryType } from "./__generated__/MeetingShowPageQuery.graphql";

const MeetingsPageQuery = graphql`
  query MeetingShowPageQuery($id: ID!) {
    meeting(id: $id) {
      date
      assignees {
        ...Avatars_users
        ...Assignment_Assignees
      }
      id
      name
      link
      description
      locked
      ... on CommentableInterface {
        ...CommentFeed_comments
      }
      tasks(order: [{ key: date, value: asc }]) {
        __id
        totalCount
        ...Checklist_TaskConnection
      }
    }
  }
`;

export default function MeetingsPageLoader() {
  const { id } = useParams();

  if (!id) return <></>;
  const { meeting } = useLazyLoadQuery<MeetingShowPageQueryType>(
    MeetingsPageQuery,
    { id: id },
  );

  if (!meeting) {
    return <></>;
  }
  const { name, link, description, assignees, tasks, locked, date } = meeting;
  const { toggleShow, Modal: AssignmentModal } = useAssignment({
    assignable: { id, type: "meeting" },
    assignees,
  });

  const { toggleShow: toggleTaskShow, Modal: NewTaskModal } = useCreateTask({
    taskable: { id, type: "meeting" },
    connectionId: meeting?.tasks?.__id || "",
  });

  const updateDescription = (desc: string, config: { setSubmitting: (a: boolean) => void }) => {
    if (locked === false) {
      updateMeeting({ id, description: desc }, { onCompleted: () => config.setSubmitting(false) });
    } else {
      config.setSubmitting(false)
    }
  };

  const onToggleLock = (desc: string) => {
    updateMeeting({ id, description: desc, locked: !locked });
  }

  const updateDate = (d: Date) => {
    updateMeeting({ id, date: d });
  };

  const dropDown = [
    {
      label: "Add invitees",
      icon: "mdi-plus-circle-outline",
      onClick: toggleShow,
    },
    {
      label: "Add task",
      icon: "mdi-plus-circle-outline",
      onClick: toggleTaskShow,
    },
  ];

  return (
    <>
      <AssignmentModal />
      <NewTaskModal />
      <Details
        onToggleLock={onToggleLock}
        updateDate={updateDate}
        showTime={true}
        updateDescription={updateDescription}
        dropdowns={dropDown}
        breadcrumbs={["Meetings"]}
        title="Meeting Details"
        usersTitle="Invitees"
        dateTitle="Date"
        commentable={{
          id,
          type: "meeting",
        }}
        task={{
          id,
          locked,
          link,
          title: name,
          description,
          users: assignees,
          dueDate: date,
          tasks,
        }}
        comments={meeting}
      />
    </>
  );
}

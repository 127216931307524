/**
 * @generated SignedSource<<1dcf2e8553fc7f818fd0ceaa8894f7e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FileLinkables = "position" | "project" | "%future added value";
export type FileLinkCreateInput = {
  clientMutationId?: string | null | undefined;
  fileLink: FileLinkCreateInputObject;
};
export type FileLinkCreateInputObject = {
  blobId?: string | null | undefined;
  fileLinkableId: string;
  fileLinkableType: FileLinkables;
  fileTypeId?: string | null | undefined;
  link?: string | null | undefined;
  name?: string | null | undefined;
};
export type CreateFileForm_Mutation$variables = {
  connections: ReadonlyArray<string>;
  input: FileLinkCreateInput;
};
export type CreateFileForm_Mutation$data = {
  readonly createFileLink: {
    readonly fileLink: {
      readonly byteSize: number | null | undefined;
      readonly contentType: string | null | undefined;
      readonly downloadUrl: {
        readonly expiresAt: any;
        readonly id: string;
        readonly url: string;
      } | null | undefined;
      readonly id: string;
      readonly link: string | null | undefined;
      readonly name: string;
    } | null | undefined;
  };
};
export type CreateFileForm_Mutation = {
  response: CreateFileForm_Mutation$data;
  variables: CreateFileForm_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "FileLink",
  "kind": "LinkedField",
  "name": "fileLink",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExpiringDownload",
      "kind": "LinkedField",
      "name": "downloadUrl",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expiresAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "byteSize",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateFileForm_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "FileLinkCreatePayload",
        "kind": "LinkedField",
        "name": "createFileLink",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateFileForm_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "FileLinkCreatePayload",
        "kind": "LinkedField",
        "name": "createFileLink",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "fileLink",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "FileTypeEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38c9a941522c89351b7e8f6597383474",
    "id": null,
    "metadata": {},
    "name": "CreateFileForm_Mutation",
    "operationKind": "mutation",
    "text": "mutation CreateFileForm_Mutation(\n  $input: FileLinkCreateInput!\n) {\n  createFileLink(input: $input) {\n    fileLink {\n      id\n      link\n      downloadUrl {\n        url\n        id\n        expiresAt\n      }\n      name\n      contentType\n      byteSize\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e4ca6c398f238530594c538ca18fb0c2";

export default node;

/**
 * @generated SignedSource<<dedfeb9d3ed99306cc7432860b6fb2d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PositionLevel = "casual" | "committee" | "executive" | "officer" | "subcommittee" | "%future added value";
export type ProjectFilter = "completed" | "incompleted" | "mine" | "overdue" | "unassigned" | "%future added value";
export type PositionsPageQuery$variables = {
  clubId?: string | null | undefined;
  filter?: ProjectFilter | null | undefined;
  search?: string | null | undefined;
};
export type PositionsPageQuery$data = {
  readonly club: {
    readonly name: string | null | undefined;
    readonly positions: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly assignees: {
            readonly " $fragmentSpreads": FragmentRefs<"Assignment_Assignees" | "Avatars_users">;
          } | null | undefined;
          readonly code: string | null | undefined;
          readonly comments: {
            readonly totalCount: number;
          } | null | undefined;
          readonly description: string | null | undefined;
          readonly id: string;
          readonly level: PositionLevel | null | undefined;
          readonly name: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    };
  } | null | undefined;
  readonly viewer: {
    readonly enums: {
      readonly position: {
        readonly levels: ReadonlyArray<PositionLevel | null | undefined>;
      };
    };
  };
};
export type PositionsPageQuery = {
  response: PositionsPageQuery$data;
  variables: PositionsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "clubId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "clubId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "level",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "CommentConnection",
  "kind": "LinkedField",
  "name": "comments",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Viewer",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EnumType",
      "kind": "LinkedField",
      "name": "enums",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EnumPositionType",
          "kind": "LinkedField",
          "name": "position",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "levels",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PositionsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Club",
        "kind": "LinkedField",
        "name": "club",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "PositionConnection",
            "kind": "LinkedField",
            "name": "positions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PositionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Position",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v2/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserConnection",
                        "kind": "LinkedField",
                        "name": "assignees",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "Avatars_users"
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "Assignment_Assignees"
                          }
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v10/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PositionsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Club",
        "kind": "LinkedField",
        "name": "club",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "PositionConnection",
            "kind": "LinkedField",
            "name": "positions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PositionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Position",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v2/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserConnection",
                        "kind": "LinkedField",
                        "name": "assignees",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "email",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "imageUrl",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v10/*: any*/)
    ]
  },
  "params": {
    "cacheID": "e7906fdcecc0026430a7b0c63c461241",
    "id": null,
    "metadata": {},
    "name": "PositionsPageQuery",
    "operationKind": "query",
    "text": "query PositionsPageQuery(\n  $clubId: ID\n  $filter: ProjectFilter\n  $search: String\n) {\n  club(id: $clubId) {\n    name\n    positions(filter: $filter, search: $search) {\n      edges {\n        node {\n          id\n          code\n          name\n          description\n          level\n          assignees {\n            ...Avatars_users\n            ...Assignment_Assignees\n          }\n          comments {\n            totalCount\n          }\n        }\n      }\n    }\n    id\n  }\n  viewer {\n    enums {\n      position {\n        levels\n      }\n    }\n  }\n}\n\nfragment Assignment_Assignees on UserConnection {\n  edges {\n    node {\n      id\n      name\n    }\n  }\n}\n\nfragment Avatar_user on User {\n  id\n  name\n  email\n  imageUrl\n}\n\nfragment Avatars_users on UserConnection {\n  ...Assignment_Assignees\n  edges {\n    node {\n      id\n      ...Avatar_user\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "03cb65350833fd4c253a4a6ff4229366";

export default node;

/**
 * @generated SignedSource<<74d891f0c25d29c8501a526f3c53a6ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProjectMonth = "april" | "august" | "december" | "february" | "january" | "july" | "june" | "march" | "may" | "november" | "october" | "september" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectsPage_project$data = {
  readonly assignees: {
    readonly " $fragmentSpreads": FragmentRefs<"Assignment_Assignees" | "Avatars_users">;
  } | null | undefined;
  readonly code: string | null | undefined;
  readonly comments: {
    readonly totalCount: number;
  } | null | undefined;
  readonly completed: boolean;
  readonly description: string | null | undefined;
  readonly id: string;
  readonly month: ProjectMonth | null | undefined;
  readonly name: string | null | undefined;
  readonly " $fragmentType": "ProjectsPage_project";
};
export type ProjectsPage_project$key = {
  readonly " $data"?: ProjectsPage_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectsPage_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectsPage_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "month",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserConnection",
      "kind": "LinkedField",
      "name": "assignees",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Avatars_users"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Assignment_Assignees"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CommentConnection",
      "kind": "LinkedField",
      "name": "comments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "15bd3be9c9f5dd7f769719a14848881b";

export default node;

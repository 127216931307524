import { PageBreadcrumb } from "@/components";
import KanbanBoard, { type MinimumColumnType as ColumnType } from "@/pagesExample/apps/tasks/Kanban";
import { useAssignment, type AssignableInputType } from "@/pages_old/Assign/Assignment";
import { graphql } from "babel-plugin-relay/macro";
import { compact, map } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLazyLoadQuery } from "react-relay";
import { permissions } from "../../services/permissions";

import TaskItem from "@/pagesExample/apps/tasks/Kanban/Task";
import { type DropResult } from "react-beautiful-dnd";
import CreatePosition from "./CreatePosition";
import updatePosition from "./UpdatePosition";

import {
  ProjectFilter as PositionFilter,
  PositionLevel,
  PositionsPageQuery as PositionsPageQueryType,
} from "./__generated__/PositionsPageQuery.graphql";

export interface PropsType {
  clubId: string;
  setFilter: (filter: PositionFilter | null) => void;
  filter: PositionFilter | null;
  search: string | null;
  children?: React.ReactNode;
}

// type MonthType = PositionMonthEnum | null;

type PositionType = Exclude<
  Exclude<
    Exclude<
      Exclude<
        Exclude<
          PositionsPageQueryType["response"]["club"],
          null | undefined
        >["positions"],
        null | undefined
      >["edges"],
      null | undefined
    >[number],
    null | undefined
  >["node"],
  null | undefined
>;

type LevelType = PositionType["level"];

graphql`
  fragment PositionsPage_position on Position {
    id
    code
    name
    description
    level
    assignees {
      ...Avatars_users
      ...Assignment_Assignees
    }
    comments {
      totalCount
    }
  }
`;

const PositionsPageQuery = graphql`
  query PositionsPageQuery(
    $clubId: ID
    $filter: ProjectFilter
    $search: String
  ) {
    club(id: $clubId) {
      name
      positions(filter: $filter, search: $search) {
        __id
        edges {
          node {
            ...PositionsPage_position @relay(mask: false)
          }
        }
      }
    }
    viewer {
      enums {
        position {
          levels
        }
      }
    }
  }
`;

const HeaderRenderer = ({ column }: { column: string | null | undefined }) => {
  const { t } = useTranslation();

  return (
    <th key={column} className="text-center col-3">
      {t(`level.${column}`)}
    </th>
  );
};

type LevelColumnType = ColumnType & {
  value: LevelType;
}

const handleLevelChange = (position: PositionType | undefined, level: LevelColumnType | undefined) => {
  if(position) {
    updatePosition({ positionId: position.id, level: level?.value ?? null });
  }
};

const PositionsPage = (props: PropsType) => {
  const { search, clubId, filter, children } = props;
  const { club, viewer } = useLazyLoadQuery<PositionsPageQueryType>(
    PositionsPageQuery,
    { search, filter, clubId },
  );
  if (!club) return <></>;
  const { positions } = club;
  const {
    enums: {
      position: { levels },
    },
  } = viewer;
  const { t } = useTranslation();

  if (!positions?.edges) {
    return <></>;
  }

  const {
    position: { create: positionCreate },
  } = permissions();

  const positionData = compact(map(compact(positions.edges), "node"));

  const columns = compact<LevelType>(levels)
    .concat([null])
    .map((c) => ({
      name: t(`level.${c}`) || '',
      value: c,
      filterKey: c || null,
    }));

  const dropDown = (item: PositionType, toggleShow: () => void) => {
    return [
      {
        label: "Add assignees",
        icon: "mdi-plus-circle-outline",
        onClick: toggleShow,
      },
      {
        label: "Open",
        icon: "mdi-open-in-new",
        href: `/organisation/${item.id}`,
      },
    ];
  };

  const Title = (
    <div>
     Organisation 
      {positionCreate && <CreatePosition connectionId={positions.__id} />}
    </div>
  )


  return (
    <>
      <div className="page-title-box mb-3">
        <PageBreadcrumb title={Title}>
          {children}
        </PageBreadcrumb>
      </div>

      <KanbanBoard
        onDragEnd={handleLevelChange}
        handleDateChange={() => {}}
        columns={columns}
        data={positionData}
        handleNewTask={() => console.log("asdf")}
        groupBy="level"
        className="tasks-small"
      >
        {({ item }) => {
          const assignable: AssignableInputType = { id: item.id, type: "position" };
          const { toggleShow, Modal } = useAssignment({
            assignable,
            assignees: item.assignees,
          });
          return (
            <>
              <Modal />
              <TaskItem
                task={{
                  id: item.id,
                  url: `/organisation/${item.id}`,
                  title: item.name,
                  users: item.assignees,
                  totalComments: item.comments?.totalCount,
                }}
                options={{
                  dropDown: dropDown(item, toggleShow),
                  assignable,
                }}
              />
            </>
          );
        }}
      </KanbanBoard>
    </>
  );
};

export default PositionsPage;

import { EditorState } from "draft-js";
import { Formik } from "formik";

import { graphql } from "babel-plugin-relay/macro";
import { Alert, Button, Container, Form } from "react-bootstrap";
import { commitMutation } from "react-relay";
import { permissions } from "../../services/permissions";
import { useRichEditor } from "../General";
import unlockMeeting from "./UnlockMeeting";

import environment from "../../Environment";

import { DescriptionFormMeetings_Mutation, type MeetingUpdateInputObject as InputType } from "./__generated__/DescriptionFormMeetings_Mutation.graphql";

const mutation = graphql`
  mutation DescriptionFormMeetings_Mutation($input: MeetingUpdateInput!) {
    updateMeeting(input: $input) {
      meeting {
        date
        assignees {
          ...Avatars_users
          ...Assignment_Assignees
        }
        id
        name
        link
        description
        locked
        ... on CommentableInterface {
          ...CommentFeed_comments
        }
        tasks(order: [{ key: date, value: asc }]) {
          __id
          totalCount
          ...Checklist_TaskConnection
        }
      }
    }
  }
`;

type ConfigType = {
  onCompleted: () => void;
};

export const updateMeeting = (meeting: InputType, config?: ConfigType) => {
  const variables = {
    input: {
      meeting,
    },
  };

  commitMutation<DescriptionFormMeetings_Mutation>(environment, {
    mutation,
    variables,
    onCompleted: config?.onCompleted,
  });
};

type FormState = { description: EditorState; locked: boolean };
type ActionType = { setSubmitting: (st: boolean) => void };
type PropsType = {
  description: string | undefined | null;
  id: string;
  locked: boolean;
};

const DescriptionForm = (props: PropsType) => {
  let timeout: ReturnType<typeof setTimeout>;
  const {
    meeting: { update: meetingUpdate, unlock: meetingUnlock },
  } = permissions();
  const { stateToString, stringToState, RichEditor } = useRichEditor();

  const sendMutation = (
    { description, locked }: FormState,
    actions: ActionType,
  ) => {
    actions.setSubmitting(true);
    updateMeeting(
      {
        description: stateToString(description),
        id: props.id,
        locked,
      },
      {
        onCompleted: () => actions.setSubmitting(false),
      },
    );
  };

  const onSubmit = ({ description }: FormState, actions: ActionType) => {
    sendMutation({ description, locked: false }, actions);
  };

  const LockAndEmail = ({ description }: FormState, actions: ActionType) => {
    sendMutation({ description, locked: true }, actions);
  };

  return (
    <Formik
      initialValues={{
        description: stringToState(props.description || ""),
        locked: props.locked,
      }}
      onSubmit={onSubmit}
      render={(actions) => {
        const { handleSubmit, values, status, isSubmitting, setSubmitting } =
          actions;

        return (
          <Container>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="description" className="pb-1">
                <RichEditor
                  name="description"
                  readOnly={!meetingUpdate || props.locked}
                  onChange={(state) => {
                    if (timeout) clearTimeout(timeout);
                    timeout = setTimeout(() => {
                      onSubmit(values, actions);
                    }, 2000);
                  }}
                />
              </Form.Group>

              {meetingUnlock && props.locked && (
                <Button
                  className="float-right mr-1"
                  variant="secondary"
                  onClick={() => unlockMeeting(props.id)}
                  disabled={isSubmitting}
                >
                  Unlock
                </Button>
              )}

              {meetingUpdate && !props.locked && (
                <>
                  <Button
                    className="float-right mr-1"
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                  <Button
                    className="float-right mr-1"
                    variant="secondary"
                    onClick={() => LockAndEmail(values, { setSubmitting })}
                    disabled={isSubmitting}
                  >
                    Lock and Email
                  </Button>
                </>
              )}

              <div style={{ paddingTop: "1rem" }}>
                {!!status && <Alert variant="danger">{status}</Alert>}
              </div>
            </Form>
          </Container>
        );
      }}
    />
  );
};

export default DescriptionForm;

import { PageBreadcrumb } from "@/components";
import { graphql } from "babel-plugin-relay/macro";
import confetti from "canvas-confetti";
import { compact, divide, filter, floor, map, multiply } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLazyLoadQuery } from "react-relay";
import { permissions } from "../../services/permissions";

import { type DropResult } from "react-beautiful-dnd";
import CreateProject from "./CreateProject";
import { updateProject } from "./DescriptionForm";
import updateProjectMonth from "./UpdateProjectMonth";

import KanbanBoard, { type MinimumColumnType as ColumnType } from "@/pagesExample/apps/tasks/Kanban";
import TaskItem from "@/pagesExample/apps/tasks/Kanban/Task";
import { useAssignment, type AssignableInputType } from "@/pages_old/Assign/Assignment";
import {
  ProjectFilter,
  ProjectMonth,
  ProjectsPageQuery as ProjectsPageQueryType,
} from "./__generated__/ProjectsPageQuery.graphql";

export interface PropsType {
  clubId: string;
  setFilter: (filter: ProjectFilter | null) => void;
  filter: ProjectFilter | null;
  search: string | null;
  children?: React.ReactNode;
}

type MonthType = ProjectMonth | null;

type ProjectType = Exclude<
  Exclude<
    Exclude<
      Exclude<
        Exclude<
          ProjectsPageQueryType["response"]["club"],
          null | undefined
        >["projects"],
        null | undefined
      >["edges"],
      null | undefined
    >[number],
    null | undefined
  >["node"],
  null | undefined
>;

graphql`
  fragment ProjectsPage_project on Project {
    id
    code
    name
    completed
    description
    month
    assignees {
      ...Avatars_users
      ...Assignment_Assignees
    }
    comments {
      totalCount
    }
  }
`;

const ProjectsPageQuery = graphql`
  query ProjectsPageQuery(
    $clubId: ID
    $filter: ProjectFilter
    $search: String
  ) {
    club(id: $clubId) {
      name
      projects(filter: $filter, search: $search) {
        __id
        edges {
          node {
            id
            ...ProjectsPage_project @relay(mask: false)
          }
        }
      }
    }
    viewer {
      currentUser {
        partyPooper
      }
      enums {
        project {
          months
        }
      }
    }
  }
`;

type MonthColumnType = ColumnType & {
  value?: MonthType;
}

const headerRenderer = ({
  column,
  data,
}: {
  column: ColumnType;
  data: ReadonlyArray<ProjectType>;
}) => {
  const totalCount = data.length;
  const completedCount = filter(data, "completed").length;
  const percent = floor(multiply(100, divide(completedCount, totalCount)), 0);
  const { t } = useTranslation();

  return (
    <>
      {t(`${column.name}`)}{" "}
      <span>
        {totalCount > 0 && (
          <>
            ({percent}%)
            {completedCount >= totalCount ? (
              <i className="mdi mdi-check text-success" />
            ) : (
              <></>
            )}
          </>
        )}
      </span>
    </>
  );
};

const handleMonthChange = (project: ProjectType | undefined, column: MonthColumnType | undefined) => {
  if(project) {
    updateProjectMonth({ projectId: project.id, month: column?.value ?? null });
  }
};

const ProjectsPage = (props: PropsType) => {
  const { children, search, clubId, filter } = props;
  const { club, viewer } = useLazyLoadQuery<ProjectsPageQueryType>(
    ProjectsPageQuery,
    { search, filter, clubId },
  );
  if (!club) return <></>;
  const { projects } = club;
  const {
    enums: {
      project: { months },
    },
    currentUser,
  } = viewer;
  const { partyPooper } = currentUser || {};

  if (!projects?.edges) {
    return <></>;
  }

  const {
    project: { create: projectCreate, update: unlocked },
  } = permissions();

  const projectData = compact(map(compact(projects.edges), "node"));

  const markComplete =
    ({ id, completed }: ProjectType) =>
    (e: React.MouseEvent) => {
      const { innerWidth: width, innerHeight: height } = window;

      if (unlocked) {
        if (!completed && !partyPooper) {
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: e.pageY / height, x: e.pageX / width },
          });
        }
        updateProject({ id, completed: !completed });
      }
    };

  const { t } = useTranslation();

  const columns = months.map((m) => {
    return {
      name: t(`month.${m}`) || '',
      value: m,
      filterKey: m || null,
    };
  });

  const dropDown = (item: ProjectType, toggleShow: () => void) => {
    return [
      {
        label: item.completed ? "Mark Incomplete" : "Mark Complete",
        icon: "mdi-pencil",
        onClick: markComplete(item),
      },
      {
        label: "Add assignees",
        icon: "mdi-plus-circle-outline",
        onClick: toggleShow,
      },
      {
        label: "Open",
        icon: "mdi-open-in-new",
        href: `/projects/${item.id}`,
      },
    ];
  };

  const Title = (
    <div>
      Projects
      {projectCreate && <CreateProject connectionId={projects.__id} />}
    </div>
  )

  return (
    <>
      <div className="page-title-box mb-3">
        <PageBreadcrumb title={Title}>
          {children}
        </PageBreadcrumb>
      </div>

      <KanbanBoard
        onDragEnd={handleMonthChange}
        handleDateChange={() => {}}
        columns={columns}
        data={projectData}
        handleNewTask={() => console.log("asdf")}
        groupBy="month"
        className="tasks-small"
        headerRenderer={headerRenderer}
      >
        {({ item }) => {
          const assignable: AssignableInputType = { id: item.id, type: "project" };
          const { toggleShow, Modal } = useAssignment({
            assignable,
            assignees: item.assignees,
          });
          return (
            <>
              <Modal />
              <TaskItem
                task={{
                  id: item.id,
                  url: `/projects/${item.id}`,
                  title: item.name,
                  users: item.assignees,
                  priority: {
                    key: item.completed ? "Low" : "High",
                    name: item.completed ? "Complete" : "Incomplete",
                  },
                  totalComments: item.comments?.totalCount,
                }}
                options={{
                  dropDown: dropDown(item, toggleShow),
                  assignable
                }}
              />
            </>
          );
        }}
      </KanbanBoard>
    </>
  );
};

export default ProjectsPage;

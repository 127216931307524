import { Formik } from "formik";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { graphql } from "babel-plugin-relay/macro";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { commitMutation } from "react-relay";

import environment from "../../Environment";

import {
  CreateMeetingForm_Mutation,
  MeetingKindEnum,
} from "./__generated__/CreateMeetingForm_Mutation.graphql";
const { loadStore } = require("../../redux/store/localStorage");

const getCurrentClub = () => {
  try {
    return loadStore().currentClub || {};
  } catch (err) {
    return {};
  }
};

const mutation = graphql`
  mutation CreateMeetingForm_Mutation(
    $input: MeetingCreateInput!
    $connections: [ID!]!
  ) {
    createMeeting(input: $input) {
      meeting
        @appendNode(connections: $connections, edgeTypeName: "MeetingEdge") {
        id
        name
        link
        description
        date
        kind
      }
    }
  }
`;

interface PropsType {
  connectionId: string;
  onSubmit: () => void;
}

type InputType = {
  name: string;
  link?: string;
  clubId: string;
  date: string;
  kind: MeetingKindEnum;
  defaultDescription: boolean;
};

const createMeeting = (
  { defaultDescription, name, link, date, kind, clubId }: InputType,
  connectionId: string,
  config?: { onCompleted: () => void },
) => {
  var validDueDate: string | null = date;
  if (validDueDate === "") {
    validDueDate = null;
  }
  const variables = {
    input: {
      meeting: {
        clubId,
        name,
        link,
        kind,
        date: validDueDate,
        defaultDescription,
      },
    },
    connections: [connectionId],
  };

  commitMutation<CreateMeetingForm_Mutation>(environment, {
    mutation,
    variables,
    onCompleted: config?.onCompleted,
  });
};

const schema = yup.object().shape({
  name: yup.string().required("Meeting name required"),
  link: yup.string().url().nullable(),
  kind: yup.string().required("Meeting kind required"),
  date: yup.string().required("Date is required"),
});

const kinds: MeetingKindEnum[] = ["agm", "commitee", "other"];
const initialValues: Omit<InputType, "clubId"> = {
  date: "",
  name: "",
  kind: "other",
  defaultDescription: false,
  link: "",
};

const MeetingForm = ({ connectionId, onSubmit }: PropsType) => {
  const { currentClub } = getCurrentClub();
  const clubId = currentClub?.id;
  const { t } = useTranslation();

  if (!clubId) return <>Unable to find club</>;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(val) => {
        createMeeting({ ...val, clubId }, connectionId, {
          onCompleted: onSubmit,
        });
      }}
      validationSchema={schema}
      render={({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        status,
        isSubmitting,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                type="text"
                placeholder="eg: Dec 2024 Committee Meeting"
                isValid={touched.name && !errors.name}
                isInvalid={touched.name && !!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="link">
              <Form.Label>Link</Form.Label>
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.link || ""}
                type="text"
                placeholder="Zoom or Google meet link."
                isValid={touched.link && !errors.link}
                isInvalid={touched.link && !!errors.link}
              />
              <Form.Control.Feedback type="invalid">
                {errors.link}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="date" className="mt-2">
              <Form.Label>Date</Form.Label>
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.date}
                type="date"
                placeholder="Due Date"
                isValid={touched.date && !errors.date}
                isInvalid={touched.date && !!errors.date}
              />
              <Form.Control.Feedback type="invalid">
                {errors.date}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="kind" className="mt-2">
              <Form.Label>Meeting Type</Form.Label>
              <div></div>
              <Form.Select
                aria-label="Default select example"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.kind}
              >
                <option value={undefined}>---</option>
                {map(kinds, (kind) => (
                  <option key={kind} value={kind}>
                    {t(`meeting.name.${kind}`)}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.kind}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="defaultDescription">
              <Form.Check
                label="Pre populate with default minutes template"
                checked={values.defaultDescription}
                onChange={handleChange}
                onBlur={handleBlur}
                type="checkbox"
                id="defaultDescription"
              />
            </Form.Group>

            <Button
              className="mt-2"
              variant="primary"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </Button>
            <div style={{ paddingTop: "1rem" }}>
              {!!status && <Alert variant="danger">{status}</Alert>}
            </div>
          </Form>
        );
      }}
    />
  );
};

export default MeetingForm;

import { PageBreadcrumb, type MenuItem } from "@/components";
import { type FileLinkables } from "@/pages_old/Files/CreateFileForm";
import { Col, Row } from "react-bootstrap";
import Comments, {
  type CommentableType,
  type ConnectionTypeKey,
} from "./CommentFeed";
import Task, { type UpdateDescriptionType, type OnToggleLockType, type TaskProps } from "./Task";
import { type Taskables } from '@/pages_old/Tasks/CreateTaskForm';

import { ListTaskItem } from "../types";
import Attachments from "./Attachments";

type PropsType = TaskProps & {
  title?: string;
  commentable?: CommentableType;
  comments?: ConnectionTypeKey;
  breadcrumbs?: string[];
}

const DetailsTask = ({
  title,
  commentable,
  comments,
  breadcrumbs = [],
  ...otherProps
}: PropsType) => {
  const { task: { attachments } } = otherProps;

  const xxl = attachments ? 8 : 12;
  const xl = attachments ? 7 : 12;
  const total = 12;

  return (
    <>
      {title && <PageBreadcrumb title={title} subName={breadcrumbs} />}
      <Row>
        <Col xxl={xxl} xl={xl}>
          <Task {...otherProps} taskType={commentable?.type as Taskables} />
          {commentable && (
            <Comments comments={comments} commentable={commentable} />
          )}
        </Col>
        {attachments && commentable && (
          <Col xxl={total - xxl} xl={total - xl}>
            <Attachments
              attachments={attachments}
              linkable={{
                id: commentable.id,
                type: commentable.type as FileLinkables,
              }}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export { DetailsTask };

import Details from "@/pagesExample/apps/tasks/Details";
import { useAssignment } from "@/pages_old/Assign/Assignment";
import { graphql } from "babel-plugin-relay/macro";
import { compact } from "lodash";
import { useFragment } from "react-relay";
import { permissions } from "../../services/permissions";
import updatePosition from '@/pages_old/Positions/UpdatePosition';
import { useCreateTask } from "@/pages_old/Tasks/CreateTaskModal";

import { ShowPagePosition_position$key as PositionType } from "./__generated__/ShowPagePosition_position.graphql";

export interface PropsType {
  position: PositionType;
}

const query = graphql`
  fragment ShowPagePosition_position on Position {
    assignees {
      ...Avatars_users
      ...Assignment_Assignees
    }
    id
    name
    link
    description
    level
    ... on CommentableInterface {
      ...CommentFeed_comments
    }
    fileTypes {
      ...Attachments_FileType
    }
    tasks(order: [{ key: date, value: asc }]) {
      __id
      totalCount
      ...Checklist_TaskConnection
    }
  }
`;

export default (props: PropsType) => {
  const position = useFragment<PositionType>(query, props.position);

  if (!position) {
    return <></>;
  }
  const {
    position: { update: positionUpdate },
    assignment: { create: assignmentCreate },
  } = permissions();

  const { id, name, description, link, assignees, fileTypes, tasks } = position;

  const { toggleShow, Modal } = useAssignment({
    assignable: { id, type: "position" },
    assignees,
  });

  const updateDescription = (desc: string, config: { setSubmitting: (a: boolean) => void }) => {
    if (positionUpdate) {
      config.setSubmitting(true);
      updatePosition({ positionId: id, description: desc }, { onCompleted: () => config.setSubmitting(false) });
    }
  };

  const type = 'position';

  const { toggleShow: toggleTaskShow, Modal: NewTaskModal } = useCreateTask({
    taskable: { id, type },
    connectionId: position?.tasks?.__id || "",
  });

  const dropDown = compact([
    assignmentCreate
      ? {
          label: "Add assignees",
          icon: "mdi-plus-circle-outline",
          onClick: toggleShow,
        }
      : undefined,
      {
        label: "Add task",
        icon: "mdi-plus-circle-outline",
        onClick: toggleTaskShow,
      }
  ]);

  return (
    <>
      <Modal />
      <NewTaskModal />
      <Details
        updateDescription={updateDescription}
        dropdowns={dropDown}
        breadcrumbs={["Org chart"]}
        title="Position Details"
        commentable={{ id, type }}
        task={{
          id,
          link,
          title: name,
          description,
          users: assignees,
          tasks,
          attachments: fileTypes,
        }}
        comments={position}
      />
    </>
  );
};

import classNames from "classnames";
import React, { MouseEvent } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export type MenuItem = {
  label: string;
  icon?: string;
  variant?: string;
  hasDivider?: boolean;
  className?: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  href?: string;
};

type CardTitleProps = {
  menuItems: Array<MenuItem>;
  title: string | React.ReactNode;
  containerClass: string;
  icon?: string;
};

export const DropDown = ({
  menuItems,
  icon,
  children,
  className = 'float-end align-items-end ',
}: {
  menuItems: MenuItem[];
  icon?: string;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <>
      {menuItems.length > 0 && (
        <Dropdown className={`${className}`}>
          <Dropdown.Toggle as={Link} to="" className="arrow-none card-drop text-muted cursor-pointer p-0 shadow-none">
            {children}
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            {(menuItems || []).map((item, index) => {
              const { className = "", label, hasDivider = false, onClick, href, variant, icon } = item;
              return (
                <React.Fragment key={label}>
                  {hasDivider && <Dropdown.Divider as="div" />}
                  <Dropdown.Item
                    onClick={onClick}
                    href={href}
                    className={`me-1 ${classNames(variant ?? "")}`}
                  >
                    {icon && (
                      <i className={classNames(icon, "mdi me-2")}></i>
                    )}
                    {label}
                  </Dropdown.Item>
                </React.Fragment>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

const CardTitle = ({
  title,
  containerClass,
  icon,
  menuItems,
}: CardTitleProps) => {
  return (
    <div className={classNames(containerClass)}>
      {typeof title === "string" ? (
        <h4 className="header-title mb-0">{title}</h4>
      ) : (
        title
      )}
      <DropDown menuItems={menuItems} icon={icon} className="align-self-start">
        <i className={classNames(icon ? icon : "mdi mdi-dots-vertical")} />
      </DropDown>
    </div>
  );
};

export default CardTitle;

import { graphql } from "babel-plugin-relay/macro";
import { createFragmentContainer } from "react-relay";

import { permissions } from "../../services/permissions";
import { updateProject } from "./DescriptionForm";

import Details from "@/pagesExample/apps/tasks/Details";
import { useAssignment } from "@/pages_old/Assign/Assignment";
import { useCreateTask } from "@/pages_old/Tasks/CreateTaskModal";

import { ShowPageProject_project$data as ProjectType } from "./__generated__/ShowPageProject_project.graphql";

export interface PropsType {
  project: ProjectType;
}

const ProjectShowPage = ({ project }: PropsType) => {
  if (!project) {
    return <></>;
  }
  const {
    project: { update: unlocked },
  } = permissions();

  const {
    id,
    name,
    link,
    completed,
    description,
    assignees,
    fileTypes,
    tasks,
  } = project;

  const markComplete = () => {
    if (unlocked) {
      updateProject({ id, completed: !completed });
    }
  };

  const updateDescription = (desc: string, config: { setSubmitting: (a: boolean) => void }) => {
    config.setSubmitting(true);
    if (unlocked) {
      updateProject({ id, description: desc }, { onCompleted: () => config.setSubmitting(false) });
    }
  };

  const { toggleShow, Modal: AssignmentModal } = useAssignment({
    assignable: { id, type: "project" },
    assignees,
  });

  const { toggleShow: toggleTaskShow, Modal: NewTaskModal } = useCreateTask({
    taskable: { id, type: "project" },
    connectionId: project?.tasks?.__id || "",
  });

  const dropDown = [
    {
      label: completed ? "Mark Incomplete" : "Mark Complete",
      icon: "mdi-pencil",
      onClick: markComplete,
    },
    {
      label: "Add assignees",
      icon: "mdi-plus-circle-outline",
      onClick: toggleShow,
    },
    {
      label: "Add task",
      icon: "mdi-plus-circle-outline",
      onClick: toggleTaskShow,
    },
  ];

  return (
    <>
      <AssignmentModal />
      <NewTaskModal />
      <Details
        updateDescription={updateDescription}
        dropdowns={dropDown}
        breadcrumbs={["Projects"]}
        title="Project Details"
        commentable={{
          id,
          type: "project",
        }}
        task={{
          id,
          link,
          title: name,
          description,
          users: assignees,
          priority: {
            name: completed ? "Complete" : "Incomplete",
            key: completed ? "Low" : "High",
          },
          tasks,
          attachments: fileTypes,
        }}
        comments={project}
      />
    </>
  );
};

export default createFragmentContainer(ProjectShowPage, {
  project: graphql`
    fragment ShowPageProject_project on Project {
      assignees {
        ...Avatars_users
        ...Assignment_Assignees
      }
      id
      name
      link
      description
      month
      completed
      ... on CommentableInterface {
        ...CommentFeed_comments
      }
      fileTypes {
        ...Attachments_FileType
      }
      tasks(order: [{ key: date, value: asc }]) {
        __id
        totalCount
        ...Checklist_TaskConnection
      }
    }
  `,
});

/**
 * @generated SignedSource<<01d94dad12613754abe529f1f000096b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PositionLevel = "casual" | "committee" | "executive" | "officer" | "subcommittee" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PositionsPage_position$data = {
  readonly assignees: {
    readonly " $fragmentSpreads": FragmentRefs<"Assignment_Assignees" | "Avatars_users">;
  } | null | undefined;
  readonly code: string | null | undefined;
  readonly comments: {
    readonly totalCount: number;
  } | null | undefined;
  readonly description: string | null | undefined;
  readonly id: string;
  readonly level: PositionLevel | null | undefined;
  readonly name: string | null | undefined;
  readonly " $fragmentType": "PositionsPage_position";
};
export type PositionsPage_position$key = {
  readonly " $data"?: PositionsPage_position$data;
  readonly " $fragmentSpreads": FragmentRefs<"PositionsPage_position">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PositionsPage_position",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "level",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserConnection",
      "kind": "LinkedField",
      "name": "assignees",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Avatars_users"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Assignment_Assignees"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CommentConnection",
      "kind": "LinkedField",
      "name": "comments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Position",
  "abstractKey": null
};

(node as any).hash = "2f009b9ca5a5c83296782f3e7e79e36b";

export default node;

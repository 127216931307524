import { CardTitle, type MenuItem } from "@/components";
import Avatars from "@/pages_old/Users/Avatars";
import Moment from "moment";
import { Card, Col, Row } from "react-bootstrap";
import "react-quill/dist/quill.bubble.css";
import CheckList from "../Details/Checklist";
import { ListTaskItem } from "../types";

const Task = ({
  task,
  className,
  markComplete,
  dropdowns = [],
}: {
  task: ListTaskItem;
  className?: string;
  markComplete?: () => void;
  dropdowns?: MenuItem[];
}) => {
  const { complete } = task;

  return (
    <Card className={className}>
      <Card.Body>
        <CardTitle
          containerClass="d-flex align-items-center justify-content-between"
          title={
            complete !== undefined && (
              <div className="form-check float-start">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="completedCheck"
                  checked={complete}
                  onChange={() => {}}
                />
                <label className="form-check-label" htmlFor="completedCheck">
                  Mark as completed
                </label>
              </div>
            )
          }
          icon="mdi mdi-dots-horizontal"
          menuItems={dropdowns}
        />

        <hr className="mt-1 mb-2" />

        <Row>
          <Col>
            <h4>{task.title}</h4>

            <Row>
              <Col>
                <p className="mt-2 mb-1 text-muted">Assigned To</p>
                <div className="d-flex">
                  {task.users && <Avatars users={task.users} />}
                </div>
              </Col>

              <Col>
                <p className="mt-2 mb-1 text-muted">Due Date</p>
                <div className="d-flex">
                  <i className="uil uil-schedule font-18 text-success me-1"></i>
                  <div>
                    <h5 className="mt-1 font-14">
                      {Moment(task.dueDate).format("D MMM")}
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>

            {task.tasks?.totalCount && (
              <Row className="mt-3">
                <CheckList tasks={task.tasks} short />
              </Row>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Task;

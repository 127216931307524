import { type Assignables } from "@/pages_old/Assign/Assignment";
import { map } from "lodash";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ListTaskItem } from "../types";
import TaskSection from "./Section";
import Task from "./Task";
export { type ListTaskItem } from "../types";

interface PropsType {
  children?: (item: ListTaskItem) => React.ReactNode;
  collapsable?: boolean;
  sections?: {
    id: string;
    title?: string;
    tasks: ListTaskItem[];
  }[];
  type?: Assignables;
  onUpdateDate?: (date: Date | null, { id }: ListTaskItem) => void;
  showTime?: boolean;
  onCheck?: (task: ListTaskItem) => void;
  showParent?: boolean;
}

const ListTask = ({
  collapsable = true,
  children,
  sections = [],
  type,
  onUpdateDate,
  showTime = false,
  showParent = false,
  onCheck,
}: PropsType) => {
  const [selectedTask, selectTask] = useState<ListTaskItem | null>();

  return (
    <Row>
      <Col xxl={selectedTask ? 8 : 12}>
        {map(sections, (section, i) => {
          return (
            <div className="mt-4" key={section.id}>
              <TaskSection
                showParent={showParent}
                showTime={showTime}
                title={section.title}
                tasks={section.tasks}
                collapsable={collapsable}
                onDateUpdate={onUpdateDate}
                type={type}
                onCheck={onCheck}
              >
                {children}
              </TaskSection>
            </div>
          );
        })}
      </Col>
      {selectedTask && (
        <Col>
          <Task task={selectedTask} className="mt-4" />
        </Col>
      )}
    </Row>
  );
};

export { ListTask };

import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import environment from "../../Environment";

import {
  UpdateProjectMonth_Mutation,
  type ProjectMonth,
} from "./__generated__/UpdateProjectMonth_Mutation.graphql";

const mutation = graphql`
  mutation UpdateProjectMonth_Mutation($input: ProjectUpdateInput!) {
    updateProject(input: $input) {
      project {
        ...ProjectsPage_project @relay(mask: false)
      }
    }
  }
`;

type InputType = {
  projectId: string;
  month: ProjectMonth | null;
};

export default ({ projectId, month }: InputType) => {
  const variables = {
    input: {
      project: {
        id: projectId,
        month,
      },
    },
  };

  commitMutation<UpdateProjectMonth_Mutation>(environment, {
    mutation,
    variables,
  });
};

import { CheckInput, PasswordInput, TextInput, useForm } from "@/components";
import AccountWrapper2 from "@/pagesExample/account/AccountWrapper2";
import PasswordReset from "@/pages_old/Session/PasswordReset";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import * as yup from "yup";

import { useSessionStore } from "@/pages_old/Session/StatusWrap";
import { useParams } from "react-router-dom";

import { DispatchType } from "@/redux/actions/CurrentClubActions";

type PropsType = {
  newUser?: boolean;
};

type StateType = "PreSet" | "Success" | "Error";

const BottomLink = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer footer-alt">
      <p className="text-muted">
        {t("Already have account?")}
        <Link to="/signin" className="text-muted ms-1">
          <b>{t("Log In")}</b>
        </Link>
      </p>
    </footer>
  );
};

const Header = ({ newUser }: { newUser: boolean }) => {
  const { t } = useTranslation();

  if (newUser) {
    return (
      <>
        <h4 className="mt-0">{t("Account Setup")}</h4>
        <p className="text-muted mb-4">{t("Set your name and password.")}</p>
      </>
    );
  }

  return (
    <>
      <h4 className="mt-0">{t("Password Reset")}</h4>
      <p className="text-muted mb-4">{t("Enter a new password")}</p>
    </>
  );
};

const schema = yup.object().shape({
  name: yup.string().required("Please enter name"),
  password1: yup
    .string()
    .required("Please enter password")
    .min(8, "Password is too short - should be 8 chars minimum"),
  password2: yup.string().oneOf([yup.ref("password1")], "Passwords must match"),
});

const Register2 = ({ newUser = false, dispatch }: PropsType & DispatchType) => {
  const { t } = useTranslation();

  // const { loading, register, isAuthenticated, schema } = useRegister();
  const { isAuthenticated } = useSessionStore({ dispatch });
  const [submitting, setSubmitting] = useState(false);
  const { id } = useParams();

  const { Form, ...other } = useForm<{
    name: string;
    password1: string;
    password2: string;
  }>({
    onSubmit: ({ name, password1 }) => {
      setSubmitting(true);
      PasswordReset({
        name: !newUser || name === "" ? undefined : name,
        password: password1,
        token: id || "",
        onSuccess: (result) => {
          if (result?.passwordReset?.status !== "success") {
            other.setError("password1", {
              message: result?.passwordReset?.status || "Unknown error",
            });
          }
        },
      });
    },
    defaultValues: {},
    schema,
  });

  return (
    <>
      {isAuthenticated && <Navigate to="/" replace />}

      <AccountWrapper2 bottomLinks={<BottomLink />}>
        <Header newUser={newUser} />
        <Form>
          {newUser && (
            <TextInput
              label={t("Name")}
              type="text"
              name="name"
              placeholder={t("Enter your name")}
              containerClass={"mb-3"}
            />
          )}

          <PasswordInput
            label={t("Password")}
            name="password1"
            placeholder={t("Enter your password")}
            containerClass={"mb-3"}
          />

          <PasswordInput
            label={t("Confirm Password")}
            name="password2"
            placeholder={t("Confirm Password")}
            containerClass="mb-3"
          />

          {false && (
            <CheckInput
              label={
                <>
                  I accept{" "}
                  <Link to="" className="text-muted">
                    Terms and Conditions
                  </Link>
                </>
              }
              type="checkbox"
              name="checkboxsignup"
              containerClass={"mb-3 text-muted"}
              defaultChecked
            />
          )}

          <div className="mb-0 d-grid text-center">
            <Button variant="primary" type="submit" disabled={submitting}>
              <i className="mdi mdi-account-circle"></i> {t("Submit")}
            </Button>
          </div>
        </Form>
      </AccountWrapper2>
    </>
  );
};

const MapStateToProps = (dispatch: any) => ({ dispatch });
export default connect(MapStateToProps)(Register2);

// Import redux types
import axios from "axios";
import { Dispatch } from "redux";
import { signOut } from "../../services/endpoint";
// Import Session Typing
import { SessionType } from "../reducers/SessionReducer";

// Create Action Constants
export enum SessionActionTypes {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
}

export interface DispatchType {
  dispatch: any;
}

// Interface for Get All Action Type
export interface SessionLogoutActionType {
  type: SessionActionTypes.LOGOUT;
  session: SessionType;
}

// Interface for Get All Action Type
export interface SessionLoginActionType {
  type: SessionActionTypes.LOGIN;
  session: SessionType;
}

interface SignInPropsType {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  created_at: number;
}

export type SessionActions = SessionLoginActionType | SessionLogoutActionType;

export const sessionSignIn = ({
  access_token,
  expires_in,
  refresh_token,
  created_at,
}: {
  access_token: string;
  expires_in: string;
  refresh_token: string;
  created_at: string;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      const session = {
        access: {
          token: access_token,
          expiresAt: expires_in + created_at,
        },
        refresh: {
          token: refresh_token,
        },
      };
      dispatch({
        session,
        type: SessionActionTypes.LOGIN,
      });
    } catch (err) {
      console.error(err);
    }
  };
};

export const sessionSignOut = () => {
  return async (dispatch: Dispatch) => {
    try {
      await axios.post(signOut());
      dispatch({
        type: SessionActionTypes.LOGOUT,
      });
    } catch (err) {
      console.error(err);
    }
  };
};
